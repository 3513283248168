import React, { useEffect } from 'react';
import animations from './animations';

function SvgTeaLeaf(props) {
  useEffect(animations, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="tea-leaf_svg__svg212"
      viewBox="0 0 5222.45 4235.37"
      height={456.106}
      width={562.405}
      xmlSpace="preserve"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs id="tea-leaf_svg__defs4">
        <style id="style2" type="text/css">
          {
            ".tea-leaf_svg__fil5{fill:#5b983a}.tea-leaf_svg__fil2,.tea-leaf_svg__fil3{fill:#6c9c38;fill-rule:nonzero}.tea-leaf_svg__fil3{fill:#5b983a}"
          }
        </style>
      </defs>
      <path
        id="tea-leaf_svg__polygon7"
        fill="#fbf8cd"
        d="M5222.45 4235.37L0 4218.61V0l5222.45 16.76z"
      />
      <path
        d="M2591.47 338.36c527.19 0 1002.89 220.44 1339.97 574.16h-916.6v292.43h1144.12c179.35 285.07 283.19 622.43 283.19 984.1 0 32.63-.87 65.05-2.53 97.27H3441.2v318.05h954.15c-25.72 112.15-61.7 220.37-106.76 323.73H2992.53v335.09h1106.09c-335.58 470.07-885.55 776.56-1507.15 776.56-743.87 0-1385.14-438.94-1679.18-1071.89h1029.55v-352.12H790.3c-32.32-136.98-49.52-279.82-49.52-426.67 0-66.96 3.57-133.09 10.51-198.21h1002.46v-335.07H818.83c82.48-274.43 226.93-522.04 417.12-726.55h1333.31V621.16h-961.24c284.93-179.1 622.05-282.78 983.43-282.78z"
        id="tea-leaf_svg__path9"
        fill="#fefefe"
      />
      <g id="tea-leaf_svg__inflammation">
        <path
          id="tea-leaf_svg__path11"
          d="M3193.78 1060.86c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3312.72 1061.26c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__1"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3346.17 1018.9h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__2"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3439.58 988.82c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__3"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3536.35 988.82c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__4"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3653.91 1061.26c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__5"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3671.13 1018.9v17.22h14.65v91.83h22.96v-91.83h23.75v-17.22h-23.95v-5.94c0-14.64 4.35-27.51 18.6-27.51 5.35 0 8.71.99 11.68 2.18l2.38-17.61c-3.96-1.58-9.5-2.97-16.63-2.97-8.9 0-19 2.77-26.52 10.09-12.27 12.27-12.27 29.88-12.27 41.76z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__6"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__7"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M3748.51 1127.95h22.95V969.23h-22.95z"
        />
        <path
          d="M3877.14 1060.86c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__8"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4055.66 1062.05c0-34.44-17.81-45.52-34.04-45.52-15.64 0-26.32 7.92-33.65 19.99h-.39c-4.55-12.27-15.04-19.99-28.89-19.99-16.63 0-26.52 9.3-31.86 18.41h-.59l-1.19-16.03h-19.79c.4 9.3.79 18.4.79 30.68v78.37h22.56v-66.1c0-17.22 11.68-26.52 21.97-26.52 13.26 0 19 11.68 19 26.92v65.7h22.56v-67.29c0-15.83 10.69-25.33 21.17-25.33 13.86 0 19.79 12.07 19.79 30.28v62.34h22.56v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__9"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4233.97 1062.05c0-34.44-17.81-45.52-34.04-45.52-15.64 0-26.32 7.92-33.65 19.99h-.39c-4.55-12.27-15.04-19.99-28.89-19.99-16.63 0-26.52 9.3-31.86 18.41h-.59l-1.19-16.03h-19.79c.4 9.3.79 18.4.79 30.68v78.37h22.56v-66.1c0-17.22 11.68-26.52 21.97-26.52 13.26 0 19 11.68 19 26.92v65.7h22.56v-67.29c0-15.83 10.69-25.33 21.17-25.33 13.86 0 19.79 12.07 19.79 30.28v62.34h22.56v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__10"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4339.06 1060.86c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__11"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4372.51 1018.9h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__12"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4485.51 1016.53c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__13"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4612.57 1017.12c-1.98-.4-3.56-.6-5.74-.6-11.88 0-23.35 8.51-28.3 22.37h-.79l-.79-19.99h-19.99c.59 10.09.79 20.58.79 33.25v75.8h22.96v-57.79c0-19 9.7-31.66 25.14-31.66 2.57 0 4.75.2 6.73.59v-21.97z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__14"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4692.52 1018.9l-17.61 61.35c-1.98 7.32-4.16 15.44-5.74 21.77h-.59c-1.58-6.33-3.76-14.65-5.94-21.77l-19.79-61.35h-25.13l37.41 100.54c.99 2.18 1.19 3.56 1.19 4.75 0 2.97-8.51 25.13-32.26 33.64l5.94 19.39c5.35-1.19 15.44-4.95 25.33-14.05 13.06-12.67 22.17-32.85 35.03-68.67l26.32-75.6h-24.15z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__15"
          fill="#6c9c38"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__mind">
        <g id="tea-leaf_svg__g345">
          <path
            className="tea-leaf_svg__fil2"
            d="M726.15 755.05v-64.91h-22.96v129.63c0 9.89-.4 21.77-.79 29.09h19.59l1.19-16.43h.59c7.32 12.86 18.21 18.6 32.26 18.6 22.56 0 45.72-18.6 45.72-58.38 0-33.25-17.22-55.22-42.15-55.22-15.24 0-26.52 6.53-33.05 17.61h-.4zm0 29.29c0-14.45 10.69-28.5 25.14-28.5 18.21 0 27.11 17.22 27.11 37.8 0 23.35-9.7 38.99-27.31 38.99-15.04 0-24.94-13.06-24.94-26.92z"
            id="tea-leaf_svg__path28"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path30"
            className="tea-leaf_svg__fil2"
            d="M868.84 737.43c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path32"
            className="tea-leaf_svg__fil2"
            d="M985.61 737.43c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path34"
            className="tea-leaf_svg__fil2"
            d="M1117.21 743.77c-5.94-3.37-15.24-6.33-25.93-6.33-22.96 0-37.8 14.45-37.8 32.66 0 12.67 8.51 23.55 27.91 31.27 13.06 5.54 17.61 10.09 17.61 18.41 0 8.31-5.94 14.25-18.21 14.25-9.3 0-19.39-3.96-24.94-7.32l-5.15 17.02c7.32 4.35 18.21 7.32 29.68 7.32 24.74 0 40.77-12.47 40.77-33.45 0-15.83-9.5-25.73-27.51-33.05-13.26-5.74-18.2-9.5-18.2-17.02 0-7.32 5.54-13.26 15.83-13.26 8.9 0 16.43 3.36 20.78 6.13l5.15-16.63z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path36"
            className="tea-leaf_svg__fil2"
            d="M1148.09 739.81h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93V739.8h-25.93v-26.92l-22.76 7.12v19.79z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path38"
            className="tea-leaf_svg__fil2"
            d="M1410.51 782.95c0-34.44-17.81-45.52-34.04-45.52-15.64 0-26.32 7.92-33.65 19.99h-.39c-4.55-12.27-15.04-19.99-28.89-19.99-16.63 0-26.52 9.3-31.86 18.41h-.59l-1.19-16.03h-19.79c.4 9.3.79 18.4.79 30.68v78.37h22.56v-66.1c0-17.22 11.68-26.52 21.97-26.52 13.26 0 19 11.68 19 26.92v65.7h22.56v-67.29c0-15.83 10.69-25.33 21.17-25.33 13.86 0 19.79 12.07 19.79 30.28v62.34h22.56v-65.9z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path40"
            className="tea-leaf_svg__fil2"
            d="M1515.21 828.27c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path42"
            className="tea-leaf_svg__fil2"
            d="M1636.72 782.16c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path44"
            className="tea-leaf_svg__fil2"
            d="M1670.17 739.81h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93V739.8h-25.93v-26.92l-22.76 7.12v19.79z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path46"
            className="tea-leaf_svg__fil2"
            d="M1815.04 781.76c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__polygon48"
            className="tea-leaf_svg__fil2"
            fill="#6c9c38"
            fillRule="nonzero"
            d="M1843.93 848.85h22.96V690.13h-22.96z"
          />
          <path
            id="tea-leaf_svg__path50"
            className="tea-leaf_svg__fil2"
            d="M2030.55 782.95c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path52"
            className="tea-leaf_svg__fil2"
            d="M2135.45 828.27c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__path54"
            className="tea-leaf_svg__fil2"
            d="M2243.31 781.76c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__polygon56"
            className="tea-leaf_svg__fil2"
            fill="#6c9c38"
            fillRule="nonzero"
            d="M2272.2 848.85h22.96V690.13h-22.96z"
          />
          <path
            id="tea-leaf_svg__path58"
            className="tea-leaf_svg__fil2"
            d="M2329 739.81h-15.44v17.22H2329v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93V739.8h-25.93v-26.92L2329 720v19.79z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
          <path
            id="tea-leaf_svg__16"
            className="tea-leaf_svg__fil2"
            d="M2487.52 782.95c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
            fill="#6c9c38"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g id="tea-leaf_svg__bone">
        <path
          id="tea-leaf_svg__path61"
          d="M3161.49 3024.55c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3338.62 3097.77c0-34.44-17.81-45.52-34.04-45.52-15.64 0-26.32 7.92-33.65 19.99h-.39c-4.55-12.27-15.04-19.99-28.89-19.99-16.63 0-26.52 9.3-31.86 18.41h-.59l-1.19-16.03h-19.79c.4 9.3.79 18.4.79 30.68v78.37h22.56v-66.1c0-17.22 11.68-26.52 21.97-26.52 13.26 0 19 11.68 19 26.92v65.7h22.56v-67.29c0-15.83 10.69-25.33 21.17-25.33 13.86 0 19.79 12.07 19.79 30.28v62.34h22.56v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path63"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3387.89 3071.26l-1.19-16.63h-20.19c.4 9.7.79 20.98.79 35.23v117.95h22.96v-57h.4c5.34 8.9 16.03 15.04 29.09 15.04 22.96 0 46.11-18.01 46.11-57.99 0-33.84-18.4-55.61-42.95-55.61-15.24 0-27.11 6.73-34.63 19h-.4zm2.38 28.3c0-16.03 11.68-28.89 25.33-28.89 17.61 0 26.92 17.22 26.92 38 0 22.56-9.7 38.99-27.51 38.99-11.28 0-24.74-8.31-24.74-27.91v-20.18z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path65"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3543.25 3052.85c-1.98-.4-3.56-.6-5.74-.6-11.88 0-23.35 8.51-28.3 22.37h-.79l-.79-19.99h-19.99c.59 10.09.79 20.58.79 33.25v75.8h22.96v-57.79c0-19 9.7-31.66 25.14-31.66 2.57 0 4.75.2 6.73.59v-21.97z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path67"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3605.59 3052.25c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path69"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3741.16 3054.63l-17.61 58.19c-2.77 9.5-5.35 18.4-7.32 27.51h-.6c-1.98-9.1-4.35-18.21-7.32-27.71l-17.81-57.99h-24.74l38.2 109.05h21.97l39.19-109.05h-23.95z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path71"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3858.13 3143.09c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path73"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3956.88 3069.87v-64.91h-22.96v129.63c0 9.89-.4 21.77-.79 29.09h19.59l1.19-16.43h.59c7.32 12.86 18.21 18.6 32.26 18.6 22.56 0 45.72-18.6 45.72-58.38 0-33.25-17.22-55.22-42.15-55.22-15.24 0-26.52 6.53-33.05 17.61h-.4zm0 29.29c0-14.45 10.69-28.5 25.14-28.5 18.21 0 27.11 17.22 27.11 37.8 0 23.35-9.7 38.99-27.31 38.99-15.04 0-24.94-13.06-24.94-26.92z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path75"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4099.57 3052.25c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path77"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4261.86 3096.98c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path79"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4366.75 3143.09c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path81"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4532.59 3097.77c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path83"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4637.49 3143.09c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path85"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4745.34 3096.59c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path87"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__polygon89"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M4774.24 3163.68h22.96v-158.72h-22.96z"
        />
        <path
          d="M4831.04 3054.63h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path91"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4989.56 3097.77c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path93"
          fill="#6c9c38"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__obesity">
        <path
          id="tea-leaf_svg__path95"
          d="M539.19 1792.81v17.22h14.65v91.83h22.96v-91.83h23.75v-17.22H576.6v-5.94c0-14.64 4.35-27.51 18.6-27.51 5.35 0 8.71.99 11.68 2.18l2.38-17.61c-3.96-1.58-9.5-2.97-16.63-2.97-8.9 0-19 2.77-26.52 10.09-12.27 12.27-12.27 29.88-12.27 41.76z"
          className="tea-leaf_svg__fil2"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M641.5 1762.73c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path97"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M739.67 1792.81l-.99 14.65h-.4c-4.55-8.71-13.66-17.02-29.88-17.02-23.75 0-46.11 20.58-46.11 57.59 0 30.48 17.61 53.24 43.34 53.24 14.05 0 24.74-6.73 30.28-16.03h.39v10.49c0 24.74-12.67 34.63-30.48 34.63-12.27 0-22.76-3.76-29.49-7.72l-5.15 17.81c8.51 5.15 21.77 7.92 34.44 7.92 51.65 0 53.43-40.18 53.43-61.95v-62.15c0-14.65.4-23.75.99-31.47h-20.38zm-3.56 63.53c0 18.21-11.87 27.31-23.75 27.31-17.02 0-26.72-15.24-26.72-36.61 0-23.35 11.08-38.4 26.92-38.4 14.05 0 23.55 11.88 23.55 26.32v21.37z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path99"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M878.4 1835.96c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path101"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M911.84 1792.81H896.4v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22H934.6v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path103"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1069.18 1790.44c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path105"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1164.37 1808.05v-64.91h-22.96v129.63c0 9.89-.4 21.77-.79 29.09h19.59l1.19-16.43h.59c7.32 12.86 18.21 18.6 32.26 18.6 22.56 0 45.72-18.6 45.72-58.38 0-33.25-17.22-55.22-42.15-55.22-15.24 0-26.52 6.53-33.05 17.61h-.4zm0 29.29c0-14.45 10.69-28.5 25.14-28.5 18.21 0 27.11 17.22 27.11 37.8 0 23.35-9.7 38.99-27.31 38.99-15.04 0-24.94-13.06-24.94-26.92z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path107"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1338.54 1881.28c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path109"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1429.38 1796.77c-5.94-3.37-15.24-6.33-25.93-6.33-22.96 0-37.8 14.45-37.8 32.66 0 12.67 8.51 23.55 27.91 31.27 13.06 5.54 17.61 10.09 17.61 18.41 0 8.31-5.94 14.25-18.21 14.25-9.3 0-19.39-3.96-24.94-7.32l-5.15 17.02c7.32 4.35 18.21 7.32 29.68 7.32 24.74 0 40.77-12.47 40.77-33.45 0-15.83-9.5-25.73-27.51-33.05-13.26-5.74-18.2-9.5-18.2-17.02 0-7.32 5.54-13.26 15.83-13.26 8.9 0 16.43 3.36 20.78 6.13l5.15-16.63z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path111"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1480.63 1762.73c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path113"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1512.69 1792.81h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path115"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1643.31 1792.81l-17.61 61.35c-1.98 7.32-4.16 15.44-5.74 21.77h-.59c-1.58-6.33-3.76-14.65-5.94-21.77l-19.79-61.35h-25.13l37.41 100.54c.99 2.18 1.19 3.56 1.19 4.75 0 2.97-8.51 25.13-32.26 33.64l5.94 19.39c5.35-1.19 15.44-4.95 25.33-14.05 13.06-12.67 22.17-32.85 35.03-68.67l26.32-75.6h-24.15z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path117"
          fill="#6c9c38"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__hydration">
        <path
          id="tea-leaf_svg__polygon119"
          className="tea-leaf_svg__fil2"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M308.79 2759.14h-61.75v-62.54h-22.96v151.59h22.96v-68.87h61.75v68.87h22.95V2696.6h-22.95z"
        />
        <path
          d="M438.02 2827.61c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path121"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__polygon123"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M469.49 2848.19h22.96v-158.72h-22.96z"
        />
        <path
          d="M542.32 2755.77l-1.19-16.63h-20.19c.4 9.7.79 20.98.79 35.23v117.95h22.96v-57h.4c5.34 8.9 16.03 15.04 29.09 15.04 22.96 0 46.11-18.01 46.11-57.99 0-33.84-18.4-55.61-42.95-55.61-15.24 0-27.11 6.73-34.63 19h-.4zm2.38 28.3c0-16.03 11.68-28.89 25.33-28.89 17.61 0 26.92 17.22 26.92 38 0 22.56-9.7 38.99-27.51 38.99-11.28 0-24.74-8.31-24.74-27.91v-20.18z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path125"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M702.23 2743.11c-5.94-3.37-15.24-6.33-25.93-6.33-22.96 0-37.8 14.45-37.8 32.66 0 12.67 8.51 23.55 27.91 31.27 13.06 5.54 17.61 10.09 17.61 18.41 0 8.31-5.94 14.25-18.21 14.25-9.3 0-19.39-3.96-24.94-7.32l-5.15 17.02c7.32 4.35 18.21 7.32 29.68 7.32 24.74 0 40.77-12.47 40.77-33.45 0-15.83-9.5-25.73-27.51-33.05-13.26-5.74-18.2-9.5-18.2-17.02 0-7.32 5.54-13.26 15.83-13.26 8.9 0 16.43 3.36 20.78 6.13l5.15-16.63z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path127"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M797.82 2709.07c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path129"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M915.38 2781.5c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path131"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1078.65 2782.29c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path133"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1169.68 2739.15l-17.61 61.35c-1.98 7.32-4.16 15.44-5.74 21.77h-.59c-1.58-6.33-3.76-14.65-5.94-21.77l-19.79-61.35h-25.13l37.41 100.54c.99 2.18 1.19 3.56 1.19 4.75 0 2.97-8.51 25.13-32.26 33.64l5.94 19.39c5.35-1.19 15.44-4.95 25.33-14.05 13.06-12.67 22.17-32.85 35.03-68.67l26.32-75.6h-24.15z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path135"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1301.09 2689.47h-22.96v61.95h-.4c-4.75-8.31-14.84-14.65-28.89-14.65-24.34 0-45.52 20.98-45.52 57.99 0 33.64 18.8 55.61 43.54 55.61 15.44 0 27.31-8.11 33.05-19.2h.59l1.19 17.02h20.19c-.4-7.52-.79-19.2-.79-29.09zm-22.96 114c0 15.24-9.7 28.3-24.54 28.3-17.42 0-26.92-16.43-26.92-37.8 0-21.57 9.7-38.99 27.31-38.99 12.87 0 24.15 10.49 24.15 28.3z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path137"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1385.21 2737.37c-1.98-.4-3.56-.6-5.74-.6-11.88 0-23.35 8.51-28.3 22.37h-.79l-.79-19.99h-19.99c.59 10.09.79 20.58.79 33.25v75.8h22.96v-57.79c0-19 9.7-31.66 25.14-31.66 2.57 0 4.75.2 6.73.59v-21.97z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path139"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1479.41 2781.1c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67H1481c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path141"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1512.86 2739.15h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path143"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1606.27 2709.07c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path145"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1678.31 2736.77c-28.89 0-51.46 20.78-51.46 57.59 0 35.23 21.57 56.01 49.87 56.01 24.54 0 51.06-16.82 51.06-57.59 0-33.45-19.59-56.01-49.48-56.01zm25.93 56.6c0 21.77-10.29 39.78-26.92 39.78-16.82 0-26.92-18.01-26.92-39.38 0-18.8 7.52-39.78 27.11-39.78 19.2 0 26.72 21.37 26.72 39.39z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path147"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M1840.59 2781.5c0-33.65-19-44.73-36.02-44.73-16.43 0-27.91 9.3-33.05 18.6h-.59l-1.19-16.23h-19.99c.4 9.3.79 18.6.79 30.68v78.37h22.96v-65.7c0-17.42 12.07-26.72 23.35-26.72 15.64 0 20.78 13.06 20.78 28.5v63.92h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path149"
          fill="#6c9c38"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__heart">
        <path
          id="tea-leaf_svg__polygon151"
          className="tea-leaf_svg__fil2"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M3640.55 2432.57h-61.75v-62.55h-22.95v151.6h22.95v-68.87h61.75v68.87h22.96v-151.6h-22.96z"
        />
        <path
          d="M3769.79 2501.04c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path153"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M3877.65 2454.53c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path155"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__polygon157"
          fill="#6c9c38"
          fillRule="nonzero"
          d="M3906.54 2521.62h22.96V2362.9h-22.96z"
        />
        <path
          d="M3963.34 2412.57h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path159"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4121.86 2455.72c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path161"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4175.7 2382.49c0-7.72-5.35-13.46-13.26-13.46-7.92 0-13.46 5.74-13.46 13.46 0 7.32 5.35 13.26 13.26 13.26 8.31 0 13.46-5.94 13.46-13.26zm-1.98 30.08h-22.96v109.05h22.96z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path163"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4279.2 2501.04c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path165"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4365.49 2410.8c-1.98-.4-3.56-.6-5.74-.6-11.88 0-23.35 8.51-28.3 22.37h-.79l-.79-19.99h-19.99c.59 10.09.79 20.58.79 33.25v75.8h22.96v-57.79c0-19 9.7-31.66 25.14-31.66 2.57 0 4.75.2 6.73.59v-21.97z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path167"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4517.68 2455.72c0-34.44-19-45.52-35.43-45.52-11.87 0-24.14 5.94-31.27 17.22h-.4v-64.52h-22.96v158.72h22.96v-66.69c0-16.43 12.07-25.73 23.35-25.73 15.44 0 20.78 13.06 20.78 28.69v63.73h22.96v-65.9z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path169"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4622.57 2501.04c-7.12 2.97-15.44 5.15-27.51 5.15-17.22 0-32.46-9.3-33.05-33.84h69.27c.4-2.77.59-5.94.59-9.7 0-26.92-12.27-52.45-42.95-52.45-30.87 0-49.08 25.93-49.08 58.38 0 33.25 19 55.22 51.85 55.22 14.84 0 26.92-2.97 34.63-6.53l-3.76-16.23zm-60.36-45.12c1.19-12.67 8.31-29.29 25.13-29.29 17.61 0 22.96 16.23 22.76 29.29z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path171"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4730.43 2454.53c0-23.16-8.9-44.33-40.96-44.33-14.65 0-27.51 4.16-35.23 9.1l4.75 15.24c6.92-4.55 16.82-7.52 26.52-7.52 21.97 0 21.97 16.23 21.97 24.34-37.41 0-60.96 13.46-60.96 40.57 0 16.82 11.68 31.86 32.06 31.86 13.66 0 24.34-6.33 30.48-14.84h.59l1.59 12.67h20.78c-1.39-7.12-1.59-16.63-1.59-25.93zm-22.36 30.28c0 12.67-10.29 22.17-22.76 22.17-8.51 0-16.03-5.34-16.03-17.41 0-19.39 20.78-22.76 38.79-22.76v18.01z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path173"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4814.14 2410.8c-1.98-.4-3.56-.6-5.74-.6-11.88 0-23.35 8.51-28.3 22.37h-.79l-.79-19.99h-19.99c.59 10.09.79 20.58.79 33.25v75.8h22.96v-57.79c0-19 9.7-31.66 25.14-31.66 2.57 0 4.75.2 6.73.59v-21.97z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path175"
          fill="#6c9c38"
          fillRule="nonzero"
        />
        <path
          d="M4836.51 2412.57h-15.44v17.22h15.44v56.4c0 14.25 2.57 23.35 8.11 29.29 4.75 5.34 12.47 8.31 21.57 8.31 7.72 0 13.86-.99 17.81-2.57l-.79-17.61c-2.77.79-5.54 1.38-10.09 1.38-9.7 0-13.85-6.53-13.85-19.99v-55.22h25.93v-17.22h-25.93v-26.92l-22.76 7.12v19.79z"
          className="tea-leaf_svg__fil2"
          id="tea-leaf_svg__path177"
          fill="#6c9c38"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__benefits">
        <path
          id="tea-leaf_svg__path179"
          d="M1837.38 3274.41h71.91c11.09 0 17.67.1 19.82.3 25.31 2.22 37.99 17.57 37.99 46.01 0 17.47-4.79 30.66-14.36 39.54-6.09 5.58-15.24 9.18-27.54 10.86 12.76 1.53 21.87 4 27.33 7.36 10.7 6.66 16.08 19.25 16.08 37.77 0 13.53-2.73 24.93-8.23 34.31-5.84 9.82-14.15 16.19-24.94 19.11-5.84 1.53-15.11 2.32-27.92 2.32h-70.15v-197.57zm42.06 44.43v35.55h21.83c14.32 0 21.5-6.37 21.5-19.11 0-10.96-6.8-16.44-20.36-16.44h-22.96zm0 73.46v37.32h22c7.64 0 13.05-.99 16.16-3.01 4.45-2.86 6.68-8.05 6.68-15.5 0-8.24-2.73-13.73-8.15-16.54-3.06-1.53-8.02-2.27-14.94-2.27z"
          className="tea-leaf_svg__fil3"
          fill="#5b983a"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon181"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2102.44 3323.88h-72.33v27.84h71.45v45.91h-71.45v28.44h72.33v45.91h-114.4v-197.57h114.4z"
        />
        <path
          d="M2270.61 3274.41v197.57h-52.6l-30.1-76.82c-7.31-18.61-14.44-42.46-21.41-71.58h-1.34c2.47 27.4 3.69 53.32 3.69 77.81v70.6h-42.06v-197.57h61.12l21.99 61.51c8.57 23.84 16.08 52.97 22.67 87.33h1.47c-3.65-40.19-5.5-71.14-5.5-92.96v-55.89h42.06z"
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__path183"
          fill="#5b983a"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon185"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2410.28 3323.88h-72.33v27.84h71.45v45.91h-71.45v28.44h72.33v45.91h-114.39v-197.57h114.39z"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon187"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2548.78 3323.88h-69.32v31.39h68.56v45.92h-68.56v70.79h-44.83v-197.57h114.15z"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon189"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2613.76 3471.98h-44.83v-197.57h44.83z"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon191"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2767.87 3325.65h-45.46v146.33h-44.84v-146.33h-45.59v-51.24h135.89z"
        />
        <path
          d="M2894.4 3287.44v56.23c-7.72-9.23-15.03-15.4-22-18.46-8.69-3.85-17.3-5.78-25.82-5.78-14.65 0-22 5.04-22 15.06 0 6.76 4.32 11.46 12.93 14.17 15.2 4.74 26.41 8.84 33.71 12.29 19.77 9.48 29.68 26.71 29.68 51.84 0 12.34-2.77 23.6-8.32 33.82-5.54 10.27-13.01 17.87-22.45 22.91-9.45 4.98-19.44 7.5-29.93 7.5-19.36 0-37.24-6.42-53.69-19.25v-51.44c8.02 9.43 15.58 15.95 22.63 19.55 9.24 4.69 18.69 7.01 28.34 7.01 14.23 0 21.37-5.23 21.37-15.65 0-5.97-3.23-10.76-9.74-14.32-1.59-.89-7.01-2.81-16.33-5.83-19.6-6.27-32.99-13.92-40.17-23.01-8.23-10.42-12.34-23.45-12.34-39.1 0-21.13 6.89-37.91 20.65-50.26 11.17-10.02 25.48-15.06 42.95-15.06 17.71 0 34.55 5.92 50.54 17.77z"
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__path193"
          fill="#5b983a"
          fillRule="nonzero"
        />
        <path
          id="tea-leaf_svg__path195"
          d="M1944.36 3552.01c63.86 0 95.83 40.28 95.83 120.86 0 26.37-3.95 49.77-11.89 70.33-14.95 38.69-44.21 58.01-87.76 58.01-45.14 0-74.11-22.22-86.82-66.73-5.65-19.73-8.48-40.42-8.48-62.02 0-80.29 33.02-120.44 99.12-120.44zm-.71 73.51c-25.37-.28-38.02 18.07-38.02 55.03 0 40.56 12.42 60.84 37.2 60.84 24.9 0 37.31-20.97 37.31-62.92 0-35.16-12.18-52.81-36.49-52.95z"
          className="tea-leaf_svg__fil3"
          fill="#5b983a"
          fillRule="nonzero"
        />
        <path
          d="M2139.13 3616.8h66.57v56.9h-55.86v119.2h-66.4v-119.2h-29.31v-56.9h29.31c-2.77-16.75-4.12-29.77-4.12-38.97 0-20.35 5.65-37.17 17.07-50.25 13.54-15.57 32.31-23.33 56.21-23.33 19.54 0 38.67 4.98 57.39 15.02v55.65c-12.48-8.93-25.37-13.36-38.61-13.36-21.48 0-32.25 13.22-32.25 39.6z"
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__path197"
          fill="#5b983a"
          fillRule="nonzero"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon199"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2500.66 3587.73h-63.75v205.16h-62.86v-205.16h-63.93v-71.86h190.54z"
        />
        <path
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__polygon201"
          fill="#5b983a"
          fillRule="nonzero"
          d="M2687.01 3585.23h-101.42v39.04h100.18v64.38h-100.18v39.87h101.42v64.37h-160.4v-277.02h160.4z"
        />
        <path
          d="M2784.24 3733.08l-14.78 59.81h-61.74l68.28-277.02h93.29l67.93 277.02h-63.57l-14.3-59.81zm62.63-64.37l-25.13-104.04-24.96 104.04z"
          className="tea-leaf_svg__fil3"
          id="tea-leaf_svg__path203"
          fill="#5b983a"
          fillRule="nonzero"
        />
      </g>
      <g id="tea-leaf_svg__leaf">
        <path
          id="tea-leaf_svg__path205"
          d="M2571.36 2129.69c6.87-7.98 83.25-65.35 215.72-125.21 51.83-23.41 131.19-25.95 189.86-58.02 34.15-18.67 59.43-78.43 94.62-97.58 57.77-31.44 133.86-32.75 187.72-67.49 102.31-65.91 156.1-168.68 220.99-230.22 38.63-36.64 131.74-58.73 151.17-85.14 49.63-67.5-42.48-107.24-243.48-76.05-270.9 42.04-626.52 328.12-756.81 575.49-67.29 127.77-108.96 66.69-51.49-66.26 53.99-124.89 205.21-241.88 215.12-345.72 14.96-156.94 86.32-643.35-14.03-252.03-28.01 109.26-162.45 306.48-244.77 503.61-44.24 105.97-58.18 249.66-103.37 373.63-35.14 96.43-97.46 176.58-154.43 245.83-.07.07-46.62 49.52-59.55 19.14-14.66-34.44 4.44-153.61 11.78-237.78 7.09-81.41 59.85-196.48 82.69-303.14 18.9-88.36 10.03-169.17 2.53-236.24-9.57-85.65-85.88-164.07-105.79-214.28-59.97-151.28-224.43-305-387.96-340.01-50.95-10.91 15.4 36.28 74.28 153.18 24.85 49.36 22.48 139.52 51.27 197.09 16.89 33.8 43.09 66.8 58.11 101.17 48.14 110.18 37.54 259.55 59.1 303.6 84.44 172.67 100.93 344.99 96.93 497.88-1.83 69.5 9.12 122.77-9.63 191.66-73.58 270.6-158.77 363.9-315.46 659.36v155.6c45.87-96.1 95.47-193.7 146.05-239.21 73.21-65.87 145.06-107.01 199.79-86.9 49.48 18.2 132.36-6.18 182.77.59 151.22 20.27 308.31 41.78 433.49-.65 214.64-72.76 378.57-191.7 604.15-188.9 41.69.52 419.54 136.4 298.57 32.71-45.17-38.71-100.38-127.69-157.34-158.83-73.23-40.03-194.91-21.05-274.21-52.91-73.85-29.67-147.06-56.86-213.54-83.55-262.24-105.31-426.79-.02-613.9 147.79-97.99 77.42-243.6 251.23-323.56 230.24-80.22-21.06 40.26-245.8 182.27-457.77 114-170.17 245.59-330.15 270.34-384.67z"
          fill="#78b038"
        />
        <path
          id="tea-leaf_svg__path207"
          d="M2571.36 2129.69c6.87-7.98 83.25-65.35 215.72-125.21 51.83-23.41 131.19-25.95 189.86-58.02 34.15-18.67 59.43-78.43 94.62-97.58 57.77-31.44 133.86-32.75 187.72-67.49 102.31-65.91 156.1-168.68 220.99-230.22 38.63-36.64 131.74-58.73 151.17-85.14 18.56-25.25 17.29-46.62-2.1-61.84-465.6-152.87-1128.85 756.71-1561.61 1377.16-93.41 201.84-115.68 206.95-233.11 429.9l-.28.54v164.49c44.39-93.15 92.32-188.51 141.28-237.15 1.1-1.1 2.2-2.18 3.32-3.24 1.11-1.07 2.24-2.13 3.37-3.16l.1-.09.11-.09.65-.57.32-.31.33-.28.03-.04.61-.53.32-.29.31-.28.32-.29.32-.28 1.24-1.1.04-.04.33-.28.33-.28.31-.3.33-.28 1.81-1.6.11-.08.32-.29.32-.28.33-.27.3-.29.34-.28.31-.27.32-.28.32-.28.04-.04.27-.24.32-.27.33-.28.32-.27.32-.28.32-.28.62-.55.33-.27 1.27-1.09.33-.28.32-.27.32-.27.02-.04 2.21-1.87.32-.27.33-.27.31-.27.31-.27.31-.26.15-.11.5-.42.32-.27.32-.26c66.03-55.23 129.71-87.37 179.31-69.11 49.48 18.2 132.36-6.18 182.77.59 151.22 20.27 308.31 41.78 433.49-.65 214.64-72.76 378.57-191.7 604.15-188.9 41.69.52 419.54 136.4 298.57 32.71-27.4-23.48-58.5-65.48-91.44-101.81-70.74-13.38-148.32-11.87-207.1-15.4-518.87-31.13-919.65 192.95-1383.02 283.2 21.72-67.36 51.89-132.17 88.38-194.6 31.5-89.56 108.38-220.25 192.9-346.43 114-170.17 245.59-330.15 270.34-384.67z"
          className="tea-leaf_svg__fil5"
          fill="#5b983a"
        />
        <path
          id="tea-leaf_svg__path209"
          d="M2064.16 1247.25c-70.96-65.92-156.74-116.73-242.27-135.04-50.95-10.91 15.4 36.28 74.28 153.18 24.85 49.36 22.48 139.52 51.27 197.09 16.89 33.8 43.09 66.8 58.11 101.17 48.14 110.18 37.54 259.55 59.1 303.6 84.44 172.67 100.93 344.99 96.93 497.88-1.33 50.36 4.06 92.19-.01 137.61 102.06-315.21-20.88-982.24-65.44-1125.97-12.76-41.19-21.35-85.68-31.97-129.51z"
          className="tea-leaf_svg__fil5"
          fill="#5b983a"
        />
      </g>
    </svg>
  );
}

export default SvgTeaLeaf;

