import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

gsap.registerPlugin(PixiPlugin, MotionPathPlugin);

const animations = ()=> {
  let i, smokes = [], smoketls=[];

  for (i=0; i<6; i++) {
    smokes[i]='smoke10'+(i+1);
    smokes[5+i]='smoke20'+(i+1);
  }

  for (i=0; i<smokes.length; i++) {
    smoketls[i] = new gsap.timeline({ repeat: -1, repeatDelay: 0, yoyo: false, smoothChildTiming: true });
  }
  
  for (i=0; i<smokes.length; i++) {
    smoketls[i]
    .fromTo('.'+smokes[i], {
      x: 100,
      y: 30,
      scale: 0,      
      opacity: 1,
    }, {
      delay: Math.random() * 6,
      duration: 6,
      ease: 'slow',
      scale: Math.random() * 2 + 2,
      x: Math.random() * 200 -500,
      y: -600,
    });
  }

  gsap.to("#leaf1", {
    duration: 1, 
    repeat: -1, 
    repeatDelay: 3, 
    yoyo: false, 
    ease: "power1.inOut",
    motionPath:{
      path: "#path1",
      align: "#path1",
      start: 0,
      alignOrigin: [0.5, 0.5]
    }
  });

  const masks = [
    ['tea', 0.5, 0],
    ['is', 0.3, -0.1],
    ['is-dot', 0.1, -0.1],
    ['a', 0.2, -0.1],
    ['cup', 0.6, -0.1],
    ['of', 0.3, -0.1],
    ['good', 1, -0.1],
    ['ideas', 1.1, -0.1],
    ['ideas-dot', 0.1, -0.1],
    ['underline', 0.2, -0.1],
    ['underline-dot', 0.1, -0.1]
  ];

  var tlc = new gsap.timeline({ repeat: 0 });
  tlc.delay(0.5);
  masks.forEach((mask, index, el) => {
      const id = `#mask-${mask[0]}`
      let path = document.querySelector(id)
      const length = path.getTotalLength()
      path.style.strokeDasharray = length;
      path.style.strokeDashoffset = length;
      tlc.to(id, { duration: mask[1], strokeDashoffset: 0});
      const id2 = `#caption-word-${mask[0]}`
      tlc.to(id2, { duration: 0.1, mask: 'none' });
    })
  
  var tl = new gsap.timeline({ repeat: -1, repeatDelay: 1, yoyo: false, smoothChildTiming: true });
  
  gsap.to("#leaf1", {
    duration: 1, 
    repeat: -1, 
    repeatDelay: 3, 
    yoyo: false, 
    ease: "power1.inOut",
    motionPath:{
      path: "#path1",
      align: "#path1",
      start: 0,
      alignOrigin: [0.5, 0.5]
    }
  });
  
  tl.to("#leaf2", {
    duration: 1, 
    delay: 0.2,
    repeat: -1, 
    repeatDelay: 3, 
    yoyo: false, 
    ease: "power1.inOut",
    motionPath:{
      path: "#path2",
      align: "#path2",
      start: 0,
      alignOrigin: [0.5, 0.5]
    }
  })
  .to("#leaf3", {
    duration: 1, 
    repeat: -1, 
    repeatDelay: 3, 
    yoyo: false, 
    ease: "power1.inOut",
    motionPath:{
      path: "#path3",
      align: "#path3",
      start: 0,
      alignOrigin: [0.5, 0.5]
    }
  })
}

export default animations;