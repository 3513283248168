import React from 'react';
import { Card, Tabs, Carousel, Typography } from 'antd';

import img1 from './gold-tea.jpg';

import product1 from '../../Home/products/gold-tea.png';
import product2 from '../../Home/products/gold-dust-tea.png';
import product3 from '../../Home/products/gold-tea-box.png';

import MenuCard from '../MenuCard'

const Bottom = () => (
  <div 
    style={{
      fontFamily: 'Barlow Condensed',
    }}
  >
    <center><Typography.Title>AVAILABLE VARIANTS</Typography.Title><br /></center>
    <Tabs defaultActiveKey={"1"} tabPosition={"left"} style={{ height: "50vh" }}>
      <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Gold Tea</h2>} key={"1"}>
        <MenuCard 
          cover={product1} 
          title="Gold Tea" 
          description={"Some tastes are aquired over generations. Gold Tea - the flavour that people look for in their morning cup of tea."}
          variants={"Available in 1Kg pack"}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Gold Dust Tea</h2>} key={"2"}>
        <MenuCard 
          cover={product2} 
          title="Gold Dust Tea" 
          description={"Gold Dust Tea - the extra strong flavour that people look for in their morning cup of tea."}
          variants={"Available in 1Kg pack"}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Gold Tea Boax Pack</h2>} key={"3"}>
        <MenuCard 
          cover={product3} 
          title="Gold Tea Box Pack" 
          description={"Gold Tea Boax Pack - the flavour that people look for in their morning cup of tea. With the added convenience of tea bags."}
          variants={"Available in 1Kg pack"}
        />
      </Tabs.TabPane>
    </Tabs> 
  </div>
)

const Right = () => (
  <Card style={{ backgroundColor: '#fbf8cd', border: 'none', height: '90vh', fontFamily: 'Barlow Condensed', fontSize: '16px' }}>
  </Card>
)

const PageCarousel = () => (
  <div>
    <Carousel autoplay dots={false} afterChange={()=>{}} effect={'fade'}>
      <div>
        <img src={img1} alt='' style={{width: '100%'}}/>
      </div>
    </Carousel>
  </div>
)

const exp = {
  Main: PageCarousel,
  Right,
  Bottom,
  Carousel: PageCarousel,
};
export default exp;
