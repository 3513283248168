import React from 'react';
import { Card, Tabs, Carousel, Typography } from 'antd';

import img1 from './tulsi-tea.jpg';

import product1 from '../../Home/products/tulsi-tea.png';

import MenuCard from '../MenuCard'

const Bottom = () => (
  <div 
    style={{
      fontFamily: 'Barlow Condensed',
    }}
  >
    <center><Typography.Title>AVAILABLE VARIANTS</Typography.Title><br /></center>
    <Tabs defaultActiveKey={"1"} tabPosition={"left"} style={{ height: "50vh" }}>
      <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Sanjivani Tulsi Tea</h2>} key={"1"}>
        <MenuCard 
          cover={product1} 
          title="Sanjivani Tulsi Tea" 
          description={"Tulsi strengthens the immune system thereby enhancing your well being."}
          variants={"Available in 1Kg pack"}
        />
      </Tabs.TabPane>
    </Tabs> 
  </div>
)

const Right = () => (
  <Card style={{ backgroundColor: '#fbf8cd', border: 'none', height: '90vh', fontFamily: 'Barlow Condensed', fontSize: '16px' }}>
  </Card>
)

const PageCarousel = () => (
  <div>
    <Carousel autoplay dots={false} afterChange={()=>{}} effect={'fade'}>
      <div>
        <img src={img1} alt='' style={{width: '100%'}}/>
      </div>
    </Carousel>
  </div>
)

const exp = {
  Main: PageCarousel,
  Right,
  Bottom,
  Carousel: PageCarousel,
};
export default exp;
