import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

const Story = (props) => {
  return (
    <Content style={{ 
        padding: '10vh 5vw', 
        minHeight: '90vh', 
        backgroundColor: '#fdfbe2',
        /*backgroundImage: 'url('+years+')', */
        backgroundPosition: '15% 80%',
        backgroundSize: '30%',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'Barlow Condensed',
        fontSize: '1.2rem',
      }}>
      <Row gutter={64}>
        <Col xs={24} sm={24} md={16} >
          <Title level={2} style={{fontSize: '3rem' }}>Our Story</Title>
          <p>
            The story of one of the oldest tea brands, Sanjivani Tea began with 
            Shreeman Dungarmal ji, working in the tea gardens of Assam as an employee 
            with 50 paisa per day wages during the independence period. Later he started 
            his own Tea shop with the name Sanjivani in Chandausi, Uttar Pradesh. 
            When he began, he had no idea of what the next century held for Sanjivani. 
            It was his passion for his work and a no-compromise attitude to bring out 
            the best that laid the foundation stones for our company. In the year 1975 
            company got registered under the name Sanjivani. His experience built the 
            foundation of one of the most successful companies, to maintain the taste 
            and quality of tea.
          </p><p>
            The experience was transferred from him to the staff working under him to 
            fulfill their duty and today Sanjivani Tea successfully brings morning 
            smiles on the faces of millions of families in India and Italy. Today 
            all the tea lovers very well know the quality of tea produced by Sanjivani. 
            Sanjivani word itself defines the work of the company, as we know Chai 
            works as an instant energy booster.
          </p><p>
            After a long journey of more than 45 years, even today Sanjivani maintains 
            its relations with the tea gardeners to continue the same taste and quality 
            of product. We have worked every day for the last 45 years to make the 
            experience ever amazing for our customers. We bring the teas directly from 
            the producers.
          </p><p>
            The most tough choices are made on the tea leaves. We always look for the 
            best raw material, whether for the regular tea or premium tea.
          </p><p>
            Cleaning and sorting out of any unwanted body from the tea leaves takes 
            place at various stages in the factory.
          </p><p>
            Careful packaging is done in order to:
          </p>
          <ul>
            <li>
              Prevent oxidation of tea leaves
            </li>
            <li>
              Prevent development of bacteria
            </li>
            <li>
              Prevent changes in color and aroma
            </li>
            <li>
              Tea leaves retain freshness
            </li>
          </ul>
          <p>
            Our foremost ambition is to offer the best blends of teas, based on natural ingredients.
          </p><p>
            Sanjivani is well-known for maintaining good relationship with suppliers and shopkeepers, such that 96% of suppliers have never been changed and they are working from generation to generation with Sanjivani.
          </p><p>
            We at Sanjivani, use the ultra-modern infrastructure in blending and packaging of tea to ensure that the best hygiene and safety standards are observed.
          </p><p>
            Sanjivani company has maintained its reputation that brings smiles and relief to people when they take a sip of it.          </p>
        </Col>
        <Col xs={24} sm={24} md={8} >
          <Title level={4}>See Also</Title>
          <ul style={{listStyle: 'none'}}>
            <li>
              <a href='/about' style={{color: 'black'}}>About Us</a>
            </li><li>
              <a href='/about/directors' style={{color: 'black'}}>Directors</a>
            </li>
          </ul>
        </Col>
      </Row>
    </Content>
  )
}

export default Story;
