import React from 'react';
import { Card, Row, Col, Typography } from 'antd';

const MenuCard = ({cover, title, description, variants}) => (
  <Row gutter={64}>
    <Col xs={24} sm={24} md={8}>
      <span style={{fontSize: '18px', fontWeight: 400}}>{description}</span>
    </Col>
    <Col xs={24} sm={24} md={10}>
      <Card 
        style={{ 
          backgroundColor: '#fbf8cd', 
          border: 'none', 
          height: '100%', 
          fontFamily: 'Barlow Condensed', 
          fontSize: '16px' 
        }}
        cover={<center><img alt={''} src={cover} style={{height: '300px', width: 'auto'}}/></center>}
      >
        <center>
          <Card.Meta 
            title={<Typography.Title level={4}  style={{color: '#70a736'}}>{title}</Typography.Title>} 
          />
        </center>
      </Card>
    </Col>
    <Col xs={24} sm={24} md={6}>
      <span style={{fontSize: '18px'}}>{variants}</span>
    </Col>
  </Row>
)

export default MenuCard
