import React, {useRef,  useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = (props) => {
  const divRef = useRef(null);
  const { location } = props;

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div id="abcd" ref={divRef}>
      {props.children}
    </div>
  )
}

export default withRouter(ScrollToTop)
