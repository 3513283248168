import React from 'react';
import { useParams } from "react-router-dom";
import { Layout, Row, Col } from 'antd';

import Products from '../Home/products';
import SanjivaniTea from './sanjivani-tea';
import MasalaTea from './masala-tea';
import ElaichiTea from './elaichi-tea';
import TulsiTea from './tulsi-tea';
import TaraTea from './tara-tea';
import GoldTea from './gold-tea';
import PremiumTea from './premium-tea';

const { Content } = Layout;

const Product = () => {
  const { brand } = useParams();
  let isDefault = false;

  let Top, Bottom;

  switch (brand) {
    case 'sanjivani-tea':
      Top = SanjivaniTea.Carousel;
      Bottom = SanjivaniTea.Bottom;
      break;
    case 'masala-tea':
      Top = MasalaTea.Carousel;
      Bottom = MasalaTea.Bottom;
      break;
    case 'elaichi-tea':
      Top = ElaichiTea.Carousel;
      Bottom = ElaichiTea.Bottom;
      break;
    case 'tulsi-tea':
      Top = TulsiTea.Carousel;
      Bottom = TulsiTea.Bottom;
      break;
    case 'tara-tea':
      Top = TaraTea.Carousel;
      Bottom = TaraTea.Bottom;
      break;
    case 'gold-tea':
      Top = GoldTea.Carousel;
      Bottom = GoldTea.Bottom;
      break;
    case 'premium-tea':
      Top = PremiumTea.Carousel;
      Bottom = PremiumTea.Bottom;
      break;
    default:
      isDefault = true;
      break;
  }

  if (isDefault) return <Products />;

  return (

    <Content 
      style={{ 
        zIndex: '10', 
        padding: '0 5vw', 
        minHeight: '40vh', 
        margin: '0 -5vw', 
        backgroundColor: '#fbf8cd', 
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={24}> 
          <Top />
        </Col>
      </Row>
      <Row
        style={{ 
          padding: '10vh 5vw', 
          backgroundColor: '#fbf8cd' 
        }}
      >
        <Col xs={24} sm={24} md={24}> 
          <Bottom />
        </Col>
      </Row>
    </Content>
  )
}

export default Product;
