import React from 'react';
import { Card, Tabs, Carousel, Row, Col, Typography } from 'antd';

import img1 from './masala-tea-bags.jpg';

import product1 from '../../Home/products/masala-tea.png';

const MenuCard = ({cover, title, description, variants}) => (
  <Row gutter={64}>
    <Col xs={8} sm={8}>
      <span style={{fontSize: '16px', fontWeight: 500}}>{description}</span>
    </Col>
    <Col xs={10} sm={10}>
      <Card 
        style={{ 
          backgroundColor: '#fbf8cd', 
          border: 'none', 
          height: '100%', 
          fontFamily: 'Barlow Condensed', 
          fontSize: '16px' 
        }}
        cover={<center><img alt={''} src={cover} style={{height: '200px', width: 'auto'}}/></center>}
      >
        <center>
          <Card.Meta 
            title={<Typography.Title level={4}  style={{color: '#70a736'}}>{title}</Typography.Title>} 
          />
        </center>
      </Card>
    </Col>
    <Col xs={6} sm={6}>
      <span style={{fontSize: '16px'}}>{variants}</span>
    </Col>
  </Row>
)

const Bottom = () => (
  <div 
    style={{
      fontFamily: 'Barlow Condensed',
    }}
  >
    <center><Typography.Title>AVAILABLE VARIANTS</Typography.Title><br /></center>
    <Tabs defaultActiveKey={"1"} tabPosition={"left"} style={{ height: "50vh" }}>
      <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Sanjivani Masala Tea</h2>} key={"1"}>
        <MenuCard 
          cover={product1} 
          title="Sanjivani Masala Tea" 
          description={"How can there be no spice in an Indian tea? Presenting Sanjivani Masala Tea. With the rich flavour and taste of healthy indian spices like ginger, cinnamon, clove and cardamom"}
          variants={"Available in 1Kg pack"}
        />
      </Tabs.TabPane>
    </Tabs> 
  </div>
)

const Right = () => (
  <Card style={{ backgroundColor: '#fbf8cd', border: 'none', height: '90vh', fontFamily: 'Barlow Condensed', fontSize: '16px' }}>
  </Card>
)

const PageCarousel = () => (
  <div>
    <Carousel autoplay dots={false} afterChange={()=>{}} effect={'fade'}>
      <div>
        <img src={img1} alt='' style={{width: '100%'}}/>
      </div>
    </Carousel>
  </div>
)

const exp = {
  Main: PageCarousel,
  Right,
  Bottom,
  Carousel: PageCarousel,
};
export default exp;
