import React from "react";
import { Layout, Typography } from "antd";

import years from "./years.jpg";

const { Content } = Layout;
const { Title } = Typography;

const Career = (props) => {
  return (
    <Content
      style={{
        padding: "10vh 5vw",
        minHeight: "90vh",
        backgroundColor: "#fdfbe2",
        /*backgroundImage: 'url('+years+')', */
        backgroundPosition: "15% 80%",
        backgroundSize: "30%",
        backgroundRepeat: "no-repeat",
        fontFamily: "Barlow Condensed",
        fontSize: "1.2rem",
      }}
    >
      <p>
        <center>
          <img
            alt=""
            src={years}
            style={{ width: "50%", paddingBottom: "1rem" }}
          />
          <Title level={4}>
            There are no vacancies at the moment. Please check back later.
          </Title>
        </center>
      </p>
    </Content>
  );
};

export default Career;
