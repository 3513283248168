import React from 'react';
import { Card, Tabs, Carousel, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import img1 from './sanjivani-tea.jpg';
import img2 from './sanjivani-tea-bags.jpg';

import product1 from '../../Home/products/sanjivani-tea.png';
import product2 from '../../Home/products/sanjivani-dust-tea.png';
import product3 from '../../Home/products/sanjivani-tea-box.png';

import MenuCard from '../MenuCard'

const Bottom = () => {
  const screens = useBreakpoint();
  return (
    <div 
      style={{
        fontFamily: 'Barlow Condensed',
        height: screens.md ? '70vh' : '110vh',
      }}
    >
      <center><Typography.Title>AVAILABLE VARIANTS</Typography.Title><br /></center>
      <Tabs defaultActiveKey={"1"} tabPosition={screens.md ? 'left' : 'top'} style={{ height: "90%" }}>
        <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Sanjivani Tea</h2>} key={"1"}>
          <MenuCard 
            cover={product1} 
            title="Sanjivani Tea" 
            description={"The original taste of Sanjivani Tea. The taste of tea that tea lovers all accross India have come to love for generations."}
            variants={"Available in 50g, 100g, 250g, 500g, 1Kg and 2Kg packs"}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Sanjivani Dust Tea</h2>} key={"2"}>
          <MenuCard 
              cover={product2} 
              title="Sanjivani Dust Tea" 
              description={"For a strong cup of tea you need Sanjivani Dust Tea. The taste of tea that tea lovers all accross India have come to love for generations."}
              variants={"Available in 50g, 100g, 250g, 500g, 1Kg and 2Kg packs"}
            />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<h2 style={{color: '#373176'}}>Sanjivani Tea Box Pack</h2>} key={"3"}>
          <MenuCard 
              cover={product3} 
              title="Sanjivani Tea Box Pack" 
              description={"The same taste of Sanjivani Tea with the added convenience of tea bags."}
              variants={"Available in 1Kg box packs"}
            />
        </Tabs.TabPane>
      </Tabs> 
    </div>
  )
}

const Right = () => (
  <Card style={{ backgroundColor: '#fbf8cd', border: 'none', height: '90vh', fontFamily: 'Barlow Condensed', fontSize: '16px' }}>
  </Card>
)

const PageCarousel = () => (
  <div>
    <Carousel autoplay dots={false} afterChange={()=>{}} effect={'fade'}>
      <div>
        <img src={img1} alt='' style={{width: '100%'}}/>
      </div>
      <div>
        <img src={img2} alt='' style={{width: '100%'}}/>
      </div>
    </Carousel>
  </div>
)

const exp = {
  Main: PageCarousel,
  Right,
  Bottom,
  Carousel: PageCarousel,
};
export default exp;
