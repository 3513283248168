import React from 'react';
import { Layout, Row, Col, Typography, Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import years from './years.jpg';

const { Content } = Layout;
const { Title } = Typography;

const About = (props) => {
  return (
    <Content style={{ 
        padding: '10vh 5vw', 
        minHeight: '90vh', 
        backgroundColor: '#fdfbe2',
        /*backgroundImage: 'url('+years+')', */
        backgroundPosition: '15% 80%',
        backgroundSize: '30%',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'Barlow Condensed',
        fontSize: '1.2rem',
      }}>
      <Row gutter={64} >
        <Col xs={24} sm={24} md={16} >
          <p>
            <img alt='' src={years} style={{width: '100%', paddingBottom: '1rem'}} />
            In the year 1975 company got registered under the name Sanjivani and 
            today all the tea lovers very well know the quality of tea produced by Sanjivani. 
            After a long journey of more than 45 years, even today Sanjivani 
            maintains its relations with the tea gardeners to continue the same 
            taste and quality of product. You will find the same quality in any 
            packet sold anywhere in India. 
            &nbsp; <a href='/about/our-story' style={{color: 'black'}}>Read More...</a>
          </p>
        </Col>
        <Col xs={24} sm={24} md={8} >
          <Title level={2} style={{fontSize: '3rem', paddingBottom: '1rem'}}>45 years in the making</Title>
          <Timeline>
            <Timeline.Item 
              style={{fontSize: '1.2rem'}}
              dot={<ClockCircleOutlined />} color="green">
                1950s - Shreeman Dungarmal ji begins his career as a worker in the tea gardens of Assam
            </Timeline.Item>
            <Timeline.Item
              style={{fontSize: '1.2rem'}}
              dot={<ClockCircleOutlined />} color="green">
                1960s - Shreeman Dungarmal ji returns to Chandausi, Uttar Pradesh and sets up his own Tea shop 
            </Timeline.Item>
            <Timeline.Item
              style={{fontSize: '1.2rem'}}
              dot={<ClockCircleOutlined />} color="green">
                1975 - Sanjivani Tea set up
            </Timeline.Item>
          </Timeline>
          <Title level={4}>See Also</Title>
          <ul style={{listStyle: 'none'}}>
            <li>
              <a href='/about/our-story' style={{color: 'black'}}>Our Story</a>
            </li><li>
              <a href='/about/directors' style={{color: 'black'}}>Directors</a>
            </li>
          </ul>
        </Col>
      </Row>
    </Content>
  )
}

export default About;
