import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Button, Drawer, Row, Col, Card } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { MenuOutlined } from "@ant-design/icons";

import "./Navbar.css";
import menuGirl from "./menu-girl.svg";

import sanjivaniTea from "../Home/products/sanjivani-tea.png";
import sanjivaniDustTea from "../Home/products/sanjivani-dust-tea.png";
import sanjivaniTeaBox from "../Home/products/sanjivani-tea-box.png";
import greenTea from "../Home/products/green-tea.png";
import yellowTea from "../Home/products/yellow-tea.png";
import goldTea from "../Home/products/gold-tea.png";
import goldDustTea from "../Home/products/gold-dust-tea.png";
import goldTeaBox from "../Home/products/gold-tea-box.png";
import premiumTea from "../Home/products/premium-tea.png";

import taraTea from "../Home/products/tara-tea.png";
import taraDustTea from "../Home/products/tara-dust-tea.png";
import masalaTea from "../Home/products/masala-tea.png";
import elaichiTea from "../Home/products/elaichi-tea.png";
import tulsiTea from "../Home/products/tulsi-tea.png";

const MenuCard = (props) => (
  <Card
    style={{
      backgroundColor: "#00000000",
      border: "none",
      paddingTop: "8px",
      paddingLeft: "8px",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Barlow, sans serif",
      textTransform: "uppercase",
      textAlign: "center",
    }}
    {...props}
  />
);

const productStyle = { height: "100px", width: "auto" };

const ProductMenu = ({ route, onClick }) => (
  <div
    style={{
      margin: 0,
      padding: "9vh 5vw",
      height: "40vw",
      backgroundImage: "url(" + menuGirl + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "cover",
      backgroundSize: "cover",
    }}
  >
    <div
      style={{
        margin: "1vh 3vw",
      }}
    >
      <Row>
        <Col xs={0} sm={0} md={6}></Col>
        <Col xs={24} sm={24} md={18}>
          <Row>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/sanjivani-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={sanjivaniTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Sanjivani Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/sanjivani-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={sanjivaniDustTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Sanjivani Dust Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Link onClick={() => onClick()} to={"/product/sanjivani-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={sanjivaniTeaBox} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Sanjivani Tea Box Pack" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Link onClick={() => onClick()} to={"/product/tara-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={taraTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Tara Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Link onClick={() => onClick()} to={"/product/tara-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={taraDustTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Tara Dust Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/gold-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={goldTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Gold Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/gold-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={goldDustTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Gold Dust Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/gold-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={goldTeaBox} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Gold Tea Box Pack" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/premium-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={premiumTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Premium Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Link onClick={() => onClick()} to={"/product/sanjivani-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={yellowTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Yellow Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/sanjivani-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={greenTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Green Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/masala-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={masalaTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Masala Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/elaichi-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={elaichiTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Elaichi Tea" />
                </MenuCard>
              </Link>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Link onClick={() => onClick()} to={"/product/tulsi-tea"}>
                <MenuCard
                  cover={
                    <center>
                      <img alt="" src={tulsiTea} style={productStyle} />
                    </center>
                  }
                >
                  <Card.Meta title="Tulsi Tea" />
                </MenuCard>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={2}></Col>
      </Row>
    </div>
  </div>
);

const isMobileWindow = (screens) => {
  const isMD = screens.md === undefined ? false : screens.md;
  //const isLG = screens.lg === undefined ? false : screens.lg
  const isSM = screens.sm === undefined ? false : screens.sm;
  const isXS = screens.xs === undefined ? true : screens.xs;
  return (isXS || isSM) && !isMD;
};

const Navigation = (props) => {
  const screens = useBreakpoint();

  const location = useLocation();
  let routes = [
    {
      key: "PRODUCTS",
      link: "Products",
      route: "/product/list",
      Menu: ProductMenu,
    },
    { key: "ABOUT", link: "About", route: "/about" },
    { key: "CAREER", link: "Career", route: "/career" },
    { key: "CONTACT", link: "Contact", route: "/contact" },
  ];
  let selectedKeys = routes.find(
    (r) => location.pathname.indexOf(r.route) === 0
  );
  if (selectedKeys) selectedKeys = [selectedKeys.key];

  if (true || location.pathname !== "/")
    routes.unshift({ key: "HOME", link: "Home", route: "/" });

  const isMobileMenu = isMobileWindow(screens);

  const [isMenuOpen, setMenuOpen] = useState(!isMobileMenu);
  const isMobileMenuOpen = isMobileMenu && isMenuOpen;

  const toggleMenuOpen = () => {
    setMenuOpen(!isMenuOpen);
  };

  const renderMenuItem = (route, isMobileMenu) => {
    const menuColor = location.pathname === route.route ? "#373176" : "white";
    return !isMobileMenu && route.Menu ? (
      <Menu.SubMenu title={route.link} key={route.key}>
        <Menu.Item
          style={{
            width: "98.7vw",
            height: "auto",
            margin: 0,
            padding: 0,
          }}
          key={route.key || route.link}
        >
          <route.Menu route={route} onClick={toggleMenuOpen} />
        </Menu.Item>
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={route.key} onClick={toggleMenuOpen}>
        <Link to={route.route} style={{ color: menuColor }}>
          {route.link}
        </Link>
      </Menu.Item>
    );
  };

  return isMobileMenu ? (
    <div>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => toggleMenuOpen()}
        visible={isMobileMenuOpen}
        style={{ marginTop: "87px" }}
      >
        <Menu selectedKeys={selectedKeys}>
          {routes.map((route) => renderMenuItem(route, isMobileMenu))}
        </Menu>
      </Drawer>
      <div style={{ float: "right" }}>
        <Button onClick={() => toggleMenuOpen()} icon={<MenuOutlined />} />
      </div>
    </div>
  ) : (
    <Menu
      style={{
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Barlow Condensed, sans serif",
        textTransform: "uppercase",
        textAlign: "center",
        height: "77px",
        color: "white",
      }}
      mode="horizontal"
      selectedKeys={selectedKeys}
      forceSubMenuRender={false}
      overflowedIndicator={<MenuOutlined />}
      triggerSubMenuAction={"hover"}
      subMenuOpenDelay={0.1}
    >
      {routes.map((route) => renderMenuItem(route, isMobileMenu))}
    </Menu>
  );
};

export default Navigation;
