import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Typography, Form, Input, Button } from "antd";
import Reaptcha from "reaptcha";

const { Content } = Layout;
const { Title } = Typography;

const captchaSiteKey = "6LeGPOMZAAAAACXLnF2zd5_pR1pymd85TIIfJIMP";
// const captchaSecretKey = '6LeGPOMZAAAAAClMBALTPy3mw2HDouNPyh414zyE';

const Contact = (props) => {
  const [verified, setVerified] = useState(false);
  const [values, setValues] = useState(null);
  const [sent, setSent] = useState(false);
  const [status, setStatus] = useState("");

  const onVerify = (recaptchaResponse) => {
    setVerified(true);
  };

  const onFormFinish = async (values) => {
    if (!verified) return;
    setValues(values);
  };

  useEffect(() => {
    if (!sent && verified && values) {
      fetch("/blog/api/contact/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(values),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setStatus(data.status);
          }
          return data;
        })
        .catch((error) => setStatus("ERR"));
      setSent(true);
      setStatus("");
    }
  }, [values, verified, sent, status]);

  const validateMessages = () => {};
  const onFieldsChange = () => {};
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={onFormFinish}
      validateMessages={validateMessages}
      onFieldsChange={onFieldsChange}
      layout="vertical"
    >
      <Content
        style={{
          zIndex: "10",
          padding: "10vh 5vw",
          minHeight: "90vh",
          fontFamily: "Barlow Condensed",
        }}
      >
        <Title style={{ fontSize: "3rem" }}>Get in touch with us</Title>
        <Row gutter={32}>
          <Col xs={24} sm={24} md={12}>
            {sent && (
              <div
                ref={() => {
                  document.documentElement.scrollTop = 0;
                }}
              />
            )}
            {sent && (!status || status === "") && <>Sending...</>}
            {sent && status === "OK" && <>Your message has been sent.</>}
            {sent && status !== "" && status !== "OK" && (
              <>
                There was an error sending your message. Please try again after
                some time.
              </>
            )}
          </Col>
        </Row>
        {!sent && (
          <Row gutter={32}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={<span style={{ fontSize: "1rem" }}>Your Name</span>}
                name={"name"}
                initialValue={""}
                placeholder={"Your name"}
              >
                <Input style={{ fontSize: "1rem" }} />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "1rem" }}>Your phone number</span>
                }
                name={"phone"}
                initialValue={""}
                placeholder={"Your phone number"}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "1rem" }}>Your email address</span>
                }
                name={"email"}
                initialValue={""}
                placeholder={"Your email address"}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontSize: "1rem" }}>Your message</span>}
                name={"message"}
                initialValue={""}
                placeholder={"How can we help. Your message."}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item>
                <Reaptcha sitekey={captchaSiteKey} onVerify={onVerify} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.7036187930184!2d77.57543799999998!3d28.439924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cbf9068cc1c2d%3A0xa918e7a80145a5ec!2s302-303%2C%20Sector%20Ecotech-1%2C%20Extension%2C%20Greater%20Noida%2C%20Uttar%20Pradesh%20201310!5e0!3m2!1sen!2sin!4v1606057177999!5m2!1sen!2sin"
                width="100%"
                height="100%"
                frameborder="0"
                style={{ border: "2px solid white" }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </Col>
          </Row>
        )}
      </Content>
    </Form>
  );
};

export default Contact;
