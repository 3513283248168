import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Typography } from 'antd';

import sanjivaniTea from './sanjivani-tea.png';
import sanjivaniDustTea from './sanjivani-dust-tea.png';
import sanjivaniTeaBox from './sanjivani-tea-box.png';
import greenTea from './green-tea.png';
import yellowTea from './yellow-tea.png';
import goldTea from './gold-tea.png';
import goldDustTea from './gold-dust-tea.png';
import goldTeaBox from './gold-tea-box.png';
import premiumTea from './premium-tea.png';

import taraTea from './tara-tea.png';
import taraDustTea from './tara-dust-tea.png';
import masalaTea from './masala-tea.png';
import elaichiTea from './elaichi-tea.png';
import tulsiTea from './tulsi-tea.png';

const MenuCard = ({link, cover, title, description}) => (
  <Link to={link}>
    <Card 
      style={{
        backgroundColor: '#fdfbe2',
        border: 'none',
        paddingTop: '8px',
        paddingLeft: '8px',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Barlow Condensed, sans serif',
        textTransform: 'uppercase',
        textAlign: 'center',
      }}
      cover={<center><img alt={''} src={cover} style={{height: '40vh'}}/></center>}
    > 
      <center>
        <Card.Meta 
          title={<Typography.Title level={4}>{title}</Typography.Title>} 
          description={<Typography.Title level={5}>{description}</Typography.Title>} 
        />
      </center>
    </Card>
  </Link>
)

const Products = () => {
  return (
  <>
    <Row>
      <Col xs={24} sm={24}>
        <center style={{margin: '8vh 4vw', fontFamily: 'Barlow Condensed, sans serif', textTransform: 'uppercase'}}><Typography.Title>Our Products</Typography.Title></center>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/gold-tea'} cover={goldTea} title="Gold Tea" description="The golden taste of tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/sanjivani-tea'} cover={sanjivaniTea} title="Sanjivani Tea" description="The classic taste of tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/tara-tea'} cover={taraTea} title="Tara Tea" description="The classic taste of tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/premium-tea'} cover={premiumTea} title="Premium Tea" description="The rich taste of premium tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/gold-tea'} cover={goldDustTea} title="Gold Dust Tea" description="The golden taste of strong tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/sanjivani-tea'} cover={sanjivaniDustTea} title="Sanjivani Dust Tea" description="The classic taste of strong tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/tara-tea'} cover={taraDustTea} title="Tara Dust Tea" description="The classic taste of strong tea " />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/tulsi-tea'} cover={tulsiTea} title="Tulsi Tea" description="Strengthens immune system" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/elaichi-tea'} cover={elaichiTea} title="Elaichi Tea" description="Cardamom tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/sanjivani-tea'} cover={yellowTea} title="Yellow Tea" description="Extra long leaves tea" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/sanjivani-tea'} cover={greenTea} title="Green Tea" description="The healthiest tea around" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/masala-tea'} cover={masalaTea} title="Masala Tea" description="Chai mein masala. Life mein spice." />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/gold-tea'} cover={goldTeaBox} title="Gold Tea" description="The golden taste of tea in box pack" />
      </Col>
      <Col xs={12} sm={12} md={8} xxl={6}>
        <MenuCard link={'/product/sanjivani-tea'} cover={sanjivaniTeaBox} title="Sanjivani Tea" description="The classic taste of tea in box pack" />
      </Col>
    </Row>
  </>
  )
}

export default Products;
