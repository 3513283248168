import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import SvgTeaLeaf from './SvgTeaLeaf.js';

const Benefits = () => {
  const screens = useBreakpoint();
  
  return (
    <div  
      id='benefitsSection'
      style={{ 
      padding: '7vh 2vw',
      height: screens.md ? '80vh' : '60vh',
      fontFamily: 'Barlow Condensed',
    }}>
      <Row>
        <Col xs={24} sm={24} md={24} >
          <center>
            <SvgTeaLeaf width={'100%'} height={screens.md ? '66vh' : '46vh'} viewBox={'0 0 5222.45 4235.37'}/>
          </center>
        </Col>
      </Row>
    </div>
  )
}

export default Benefits;
