import React from "react";
import { Carousel } from "antd";

import img1 from "./slider-1.jpg";
import img2 from "./slider-2.jpg";
import img3 from "./slider-3.jpg";

const Slider = (autoplay) => {
  return (
    <Carousel
      autoplay
      pauseOnHover={false}
      effect={"fade"}
      dots={false}
      style={{
        fontFamily: "Barlow Condensed",
        width: "100%",
      }}
    >
      <img alt="" src={img1} />
      <img alt="" src={img2} />
      <img alt="" src={img3} />
    </Carousel>
  );
};

export default Slider;
