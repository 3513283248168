
import React from 'react';
import { Layout, Affix } from 'antd';
import logo from './logo.png';
import name from './sanjivani.svg';
import NavBar from '../Navbar';

const { Header  } = Layout;

const AppHeader = () => {
  return (
    <Affix offsetTop={0} style={{ zIndex: 1051}}>
      <div style={{ zIndex: 1051, backgroundColor: '#fbf8cd'}}>
        <Header className="site-layout-background" style={{ zIndex: 1051, padding: '8px 5vw' }}>
          <div className="logo" style={{float: 'left', padding: '4px 10vw 0 5vw', width: '20%'}}>
            <a href='/'>
              <div style={{maxWidth: '336px', width: '40vw'}}>
                <div style={{float: 'right', paddingLeft: '20px'}}>
                  <img src={name} style={{maxWidth: '200px', width: '20vw'}} alt=''/>
                </div>
                <div style={{float: 'right'}}>
                  <img src={logo} style={{maxHeight: '87px', maxWidth: '13vw'}} alt=''/>
                </div>
              </div>
            </a>
          </div>
          <div style={{float: 'right', width: '50%' }}>
            <NavBar style={{float: 'right', height: '66px', paddingRight: '65px'}}/>
          </div>
        </Header>
        <div style={{width: '100%', height: '4px', marginTop: '6px', minHeight: '6px', backgroundColor: '#70A736'}} />
      </div>
  </Affix>
  )  
}

export default AppHeader;
