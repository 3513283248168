import React from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import Hero from "../Hero";
import Products from "./products";
import Slider from "./slider/Slider";
import Testimonial from "./testimonial/Testimonial";
import Benefits from "./benefits/Benefits";

import cup from "./cup.jpg";
const { Content } = Layout;

const Home = (props) => {
  return (
    <>
      <Content
        style={{
          zIndex: "10",
          padding: "0 5vw",
          minHeight: "90vh",
          backgroundColor: "#fbf8cd",
        }}
      >
        <Hero />
      </Content>
      <Content
        style={{
          zIndex: "10",
          width: "100%",
          padding: "0",
          backgroundColor: "#fbf8cd",
        }}
      >
        <Slider />
        <Content
          style={{
            zIndex: "10",
            padding: "0",
            height: "4vh",
            backgroundColor: "#fbf8cd",
          }}
        ></Content>
      </Content>
      <Content
        style={{
          zIndex: "10",
          minHeight: "90vh",
          padding: "4vh 6vw 0 6vw",
          backgroundColor: "#fbf8cd",
          fontFamily: "Barlow Condensed",
        }}
      >
        <Row gutter={64} style={{ fontSize: "1.5rem" }}>
          <Col xs={24} sm={24} md={12}>
            <img
              alt=""
              src={cup}
              style={{ maxHeight: "90vh", maxWidth: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div style={{ width: "90%", paddingTop: "2.5rem" }}>
              <p>
                Across the world, tea is the most consumed beverage. After
                water, of course. People all over the world have been consuming
                tea for thousands of years.
              </p>
              <p>
                Tea is a sophisticated beverage with complex aromas and a wide
                range of flavors. In fact, tea is more than a beverage; it
                creates pleasant memories! It is not only an energizing drink
                with a distinct flavor and aroma, but it also provides health
                benefits. Let us enter the world of tea!
              </p>
              <div
                style={{ width: "100%", textAlign: "right", color: "black" }}
              >
                <Link to="/about">
                  <b style={{ color: "rgba(0, 0, 0, 0.75)" }}>Know more...</b>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
      <Content
        style={{
          zIndex: "10",
          padding: "0",
          height: "1.5vh",
          backgroundColor: "#d8e8a3",
        }}
      ></Content>
      <Content
        style={{ zIndex: "10", padding: "0 5vw", backgroundColor: "#fdfbe2" }}
      >
        <Products />
      </Content>
      <Content
        style={{
          zIndex: "10",
          padding: "0",
          height: "1.5vh",
          backgroundColor: "#d8e8a3",
        }}
      ></Content>
      <Content
        style={{ zIndex: "10", padding: "2vh 2vw", backgroundColor: "#fbf8cd" }}
      >
        <Testimonial />
      </Content>
      <Content
        style={{ zIndex: "10", padding: "2vh 2vw", backgroundColor: "#fbf8cd" }}
      >
        <Benefits />
      </Content>
    </>
  );
};

export default Home;
