import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Layout, BackTop } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import ScrollToTop from "./ScrollToTop";
import Home from "./Component/Home";
import About, { OurStory, KeyPeople } from "./Component/About";
import Career from "./Component/Career";
import Product from "./Component/Product";
import Contact from "./Component/Contact";
import Header from "./Component/Header";
import Footer from "./Component/Footer";

import ReactGA from "react-ga";

const TRACKING_ID = process.env.REACT_APP_GTAG_ID;
ReactGA.initialize(TRACKING_ID);

const RouteChangeTracker = withRouter(({ history }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return <div></div>;
});

const App = () => {
  return (
    <div id="topDiv">
      <Router>
        <Layout>
          <ScrollToTop>
            <Layout className="site-layout">
              <Header />
              <Switch>
                <Route path="/product/:brand">
                  <Product />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/about/our-story">
                  <OurStory />
                </Route>
                <Route path="/about/directors">
                  <KeyPeople />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/career">
                  <Career />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
              <Footer />
              <BackTop style={{ right: "6vw" }}>
                <UpCircleOutlined
                  style={{ fontSize: "32px", color: "green" }}
                />
              </BackTop>
            </Layout>
          </ScrollToTop>
        </Layout>
        <RouteChangeTracker />
      </Router>
    </div>
  );
};

export default App;
