import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(PixiPlugin, MotionPathPlugin, ScrollTrigger);

const animations = ()=> {
  const animLeft = {
    scrollTrigger: {
      trigger: "#benefitsSection",
      toggleActions:"restart complete reverse reset",
    },
    x: -5500,
    y: 4300,
    rotate: -90,
    duration: 0.5, 
  };

  const animRight = {
    ...animLeft,
    rotate: 90,
    x: 5500,
    y: 4300,
  }

  const animLeaf = {
    ...animLeft,
    scale: 0.2,
  }

  gsap.from("#tea-leaf_svg__leaf", animLeaf);
  gsap.from("#tea-leaf_svg__mind", animLeft);
  gsap.from("#tea-leaf_svg__obesity", animLeft);
  gsap.from("#tea-leaf_svg__hydration", animLeft);

  gsap.from("#tea-leaf_svg__inflammation", animRight);
  gsap.from("#tea-leaf_svg__heart", animRight);
  gsap.from("#tea-leaf_svg__bone", animRight);

  gsap.from('#tea-leaf_svg__benefits', animRight)

}

export default animations;
