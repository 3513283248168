import React from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import "./footer.css";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        zIndex: 10,
        color: "white",
        fontSize: "14px",
      }}
    >
      <br />
      <Row>
        <Col xs={12} sm={12} md={6}>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/about/our-story">Our Story</Link>
            </li>
            <li>
              <Link to="/about#org">Organization</Link>
            </li>
            <li>
              <Link to="/about/directors">Directors' Information</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <ul>
            <li>
              <Link to="/product">Our Products</Link>
            </li>
            <li>
              <Link to="/product#new">New Launches</Link>
            </li>
            <li>
              <Link to="/product#marketing">Marketing</Link>
            </li>
            <li>
              <Link to="/product#promotions">Current Promotions</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/about#news">Latest News</Link>
            </li>
            <li>
              <Link to="/about#archives">Archives</Link>
            </li>
            <li>
              <Link to="/about#policies">Policies</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/SanjivaniTeaOfficial"
              >
                <FacebookOutlined />
                &nbsp; Facebook
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/SanjivaniTeaIndia"
              >
                <InstagramOutlined />
                &nbsp; Instagram
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/TeaSanjivani"
              >
                <TwitterOutlined />
                &nbsp; Twitter
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCpgRX6uYrqLAaWWt2Lugsmg"
              >
                <YoutubeOutlined />
                &nbsp; Youtube
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      ©2020. All rights reserved.
    </Footer>
  );
};

export default AppFooter;
