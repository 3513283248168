import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import SvgYears from './SvgYears';
import SvgYearsTall from './SvgYearsTall';

const Testimonial = () => {
  const screens = useBreakpoint();

  return (
    <div  
      id='testimonialSection'
      style={{ 
      padding: '7vh 2vw',
      height: '80vh',
      fontFamily: 'Barlow Condensed',
    }}>
      <Row>
        <Col xs={24} sm={24} md={24} >
          {
            screens.md ? 
              <SvgYears width={'100%'} height={'72vh'} viewBox={'0 0 80793.6 30147.96'}/>
            :
              <SvgYearsTall width={'100%'} height={'72vh'} viewBox={'0 0 40161.916 60245.509'}/>
          }
        </Col>
      </Row>
    </div>
  )
}

export default Testimonial;
