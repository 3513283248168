import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(TextPlugin, MotionPathPlugin, ScrollTrigger);

const testimonialText = [
  {
    HeadingText: "It's a habit",
    Line1Text:
      "If you have consumed Sanjivani Tea for a few weeks then no other tea taste",
    Line2Text:
      "can satisfy you. We are a family of tea lovers. Tea means Sanjivani for us.",
    Line3Text:
      "We think Sanjivani Tea is gift of our ancestors who knew the real taste of tea.",
    FooterText: "Shivani from Jaipur",
  },
  {
    HeadingText: "Nothing else like it",
    Line1Text:
      "Very few tea brands understand the our tastes well enough. On the otherhand",
    Line2Text:
      "Sanjivani Tea touches our taste buds in a way that makes me realise that ",
    Line3Text: "there is nothing else like it.",
    FooterText: "Sanjay from Mumbai",
  },
];
const animations = (prefix) => {
  return () => {
    const animText = {
      scrollTrigger: {
        trigger: "#testimonialSection",
        start: "top 50%",
        end: "bottom 25%",
        toggleActions: "restart complete reverse reset",
      },
      duration: 0.1,
      repeat: -1,
      repeatDelay: 2,
      yoyo: true,
      ease: "none",
    };

    testimonialText.map((testimonial) => {
      return Object.keys(testimonial).map((key) => {
        return gsap.to("#" + prefix + key, {
          ...animText,
          text: testimonial[key],
        });
      });
    });
  };
};

export default animations;
