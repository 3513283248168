import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

const People = (props) => {
  return (
    <Content style={{ 
        padding: '10vh 5vw', 
        minHeight: '90vh', 
        backgroundColor: '#fdfbe2',
        /*backgroundImage: 'url('+years+')', */
        backgroundPosition: '15% 80%',
        backgroundSize: '30%',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'Barlow Condensed',
        fontSize: '1.2rem',
      }}>
      <Row gutter={64}>
        <Col xs={24} sm={24} md={16} >
          <Title level={2} style={{fontSize: '3rem' }}>Directors</Title>
          <Title level={3} style={{fontSize: '2rem' }}>Jay Prakash</Title>
          <p>
            He is a dynamic and visionary leader. He is our management and tasting specialist. His innovations lead the company to strategic growth.
            His journey in the tea sector started by his visits to the Tea gardens during his school years. He also studied the traditional ways of tasting and checking the quality of tea. His love for tea led him to research about tea and travelling across different tea loving countries like Malaysia, Singapore, Spain , Italy , Switzerland was a big part of it. This gave him huge information about the tea market and the taste of public. Joining the tea business with all his new research gave a new vision to the company.
            Today after many years into the business, his bond with tea is even stronger and he can determine the quality and taste of tea right through its smell and touch.
          </p>
          <Title level={3} style={{fontSize: '2rem' }}>Kamal Saini (K.P Saini)</Title>
          <p>
            His journey in the field of tea started right from his childhood while studying tea market condition. He travelled to different parts of India in order to do market research and even before graduating, he had travelled across 22 states of India, gathered information for the tea business and also took the responsibility of marketing. His experience through market research took this business to next level.
          </p>
        </Col>
        <Col xs={24} sm={24} md={8} >
          <Title level={4}>See Also</Title>
          <ul style={{listStyle: 'none'}}>
            <li>
              <a href='/about' style={{color: 'black'}}>About Us</a>
            </li><li>
              <a href='/about/our-story' style={{color: 'black'}}>Our Story</a>
            </li>
          </ul>
        </Col>
      </Row>
    </Content>
  )
}

export default People;
